import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { mainWhite, mainNavy, screen } from "../components/common/variables";
import Nav from "../components/nav";
import StyledButton from "../components/styled-button";
import BottomNav from "../components/bottom-nav";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 180px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 180px 0 0 0;
    }

    .texts {
      margin: 0 100px 0 0;

      h1 {
        font-size: 2.6rem;
        font-weight: 500;
        margin: 0 0 18px 0;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0 16px 0;
      }

      ul {
        padding: 0 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .btns {
      margin: 82px 0 0 50px;
      &__each {
        margin: 0 0 14px 0;
      }
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList} className="inner-wrapper">
          <motion.div variants={variantsItem} className="texts">
            <h1>Let’s start by selecting your goal.</h1>
            <p>Our Application Modernization Platform helps your business:</p>
            <ul>
              <li>Accelerate Windows Virtual Desktop migration</li>
              <li>Modernize Windows applications to MSIX</li>
              <li>Manage an effective deployment of Windows Virtual Desktop</li>
            </ul>
          </motion.div>
          <motion.div variants={variantsItem} className="btns">
            <StyledButton to={"/discover/1"} className="btns__each">
              DISCOVER
            </StyledButton>
            <StyledButton to={"/modernize/1"} className="btns__each">
              MODERNIZE
            </StyledButton>
            <StyledButton to={"/manage/1"} className="btns__each">
              MANAGE
            </StyledButton>
          </motion.div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
